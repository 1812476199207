




































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































/deep/ .el-switch__label--left.is-active+.el-switch__core {
  background: #ff4949 !important;
}

.el-textarea {
  .el-textarea__inner {
    height: 10rem !important;
    min-height: 200px !important;
  }
}

.Basicinformation {
  display: flex;
  flex-wrap: wrap;

  >div {
    width: 50%;
    padding: 10px 0;
  }

  .information {
    display: flex;
    flex-wrap: wrap;

    .information-item {
      width: 50%;
    }
  }
}

.lessonNum {
  display: flex;
  padding: 1rem;

  span {
    padding: 0 1rem;
  }
}

.lessNumcount {
  display: flex;
  align-items: center;
  padding: 0 2rem;

  span {
    width: 10rem;
  }
}

.bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-tree-node {
  .el-form-item {
    margin-bottom: 0px;
  }
}

.qualificationImageId {
  .el-image {
    display: flex;
    height: 500px;
  }
}

.dataqualificationImageId {
  margin: 0 1rem;
}

.imgUpload {
  display: flex;
  justify-content: center;
}

.courseIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../assets/courseIcon.png") no-repeat;
  background-size: cover;
}

.paperIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../assets/paperIcon.png") no-repeat;
  background-size: cover;
}

.nullIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

.level {
  padding: 3px;
  border-radius: 6px;
  color: #333;
  background-color: #e0f2ff;
  min-width: 20px;
  text-align: center;
}
